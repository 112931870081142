import React, { useEffect, useRef, useState } from "react";
import Skills from "./Skills/Skills";
import Service from "../Service/Service";
import Portfolio from "../Portfolio/Portfolio";
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const About = ({ language }) => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState(''); 
  const [email, setEmail] = useState(''); 
  const [message, setMessage] = useState(''); 
  const [isLoading, setIsLoading] = useState(false); 
  const form = useRef();

  useEffect(() => {
    if(language === "km") {
      i18n.changeLanguage("km")
    }
  }, []);

  const afterSend = (isSuccess = true) => {
    Swal.fire({
      title: t('Send Message Successfully!'),
      html: t('Thank you') + (isSuccess ? '!' : null),
      icon: 'success',
      confirmButtonColor: '#3b82f6'
    });
    setName('');
    setEmail('');
    setMessage('');
    setIsLoading(false);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs.sendForm('service_ia7ygaw', 'template_3ghm7ml', form.current, '3gDWbNLcDUimvHmce')
      .then((res) => {
        afterSend()
      }, (error) => {
        afterSend(false)
        console.log(error.text);
      });
  };
  
  return (
    <section className="py-8">
      {!isLoading ? null :
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        <h2 className="text-center text-white text-xl font-semibold">{t('Sending message')}...</h2>
        {/* <p className="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p> */}
      </div>}
          {/* <div className="py-2">
            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="md:mx-4">
                  <h3 className="text-2xl text-gray-800 font-bold">{t('Services')}</h3>
                </div>
              </div>
            </div>
          </div> */}
          {/* <Service /> */}
      <div className="flex flex-wrap md:px-4">
        <div className="w-full">
          <div className="md:mx-4 mb-3">
            <h3 className="text-2xl text-gray-800 font-bold mb-4">{t('About Me')}</h3>
              <p className="text-sm text-gray-600 leading-6 mb-3">
                {t("With coding experience since 2018 and having completed accelerator programs from 500 Startups and BIG by SMU (Singapore), I am a skilled Information Technology Director with a proven track record of leading and managing IT projects, including expertise in web development, API development, project management, and team leadership, fostering strong collaboration with all stakeholders to ensure project success.")}
              </p>
              <h3 className="text-2l text-gray-800 font-bold mb-4">{t('Experience')}</h3>
              <div className="ml-10">
                <p className="text-sm text-gray-600 leading-6 mb-3">
                  {t('Experience1')}
                </p>
                <p className="text-sm text-gray-600 leading-6 mb-3">
                  {t('Experience2')}
                </p>
                <p className="text-sm text-gray-600 leading-6 mb-3">
                  {t('Experience3')}
                </p>
                <p className="text-sm text-gray-600 leading-6 mb-3">
                  {t('Experience4')}
                </p>
                <p className="text-sm text-gray-600 leading-6 mb-3">
                  {t('Experience5')}
                </p>
                <p className="text-sm text-gray-600 leading-6 mb-3">
                  {t('Experience6')}
                </p>
                <p className="text-sm text-gray-600 leading-6 mb-3">
                  {t('Experience7')}
                </p>
                <p className="text-sm text-gray-600 leading-6 mb-3">
                  {t('Experience8')}
                </p>
                <p className="text-sm text-gray-600 leading-6 mb-3">
                  {t('Experience9')}
                </p>
              </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="md:mx-4">
                <h3 className="text-2xl text-gray-800 font-bold">{t('Works')}</h3>
              </div>
            </div>
          </div>
          <Portfolio />
          <section>
            <div className="py-8 lg:py-2 px-4 mx-auto max-w-screen-md">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">{t('Send me a message')}</h2>
                {/* <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Got a technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.</p> */}
                <form ref={form} onSubmit={sendEmail} className="space-y-8">
                    <div>
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{t('Your name')}</label>
                        <input type="text" name="user_name" value={name} onChange={(event) => setName(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="example" required />
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{t('Your email')}</label>
                        <input type="email" name="user_email" value={email} onChange={(event) => setEmail(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700" placeholder="example@gmail.com"/>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">{t('Your message')}</label>
                        <textarea name="message" rows="6" value={message} onChange={(event) => setMessage(event.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700" placeholder={t('Leave a comment') + "..."}></textarea>
                    </div>
                    <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-700 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">{t('Send message')}</button>
                </form>
            </div>
          </section>
          {/* <Skills /> */}
        </div>
      </div>
    </section>
  );
};

export default About;
